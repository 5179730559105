<template>
  <div
    class="news-list-item"
    :class="{ 'news-list-item--reverse': i % 2 !== 0 }"
  >
    <div class="news-list-item__img">
      <div class="news-list-item__img-content" :style="bgStyles"></div>
    </div>
    <div class="news-list-item__descr">
      <h2 class="news-list-item__title">{{ news.headline }}</h2>
      <div class="news-list-item__text" v-html="news.simple_text"></div>
      <router-link
        :to="{ name: 'NewsDetail', params: { id: news.id } }"
        class="news-list-item__link"
      >
        Подробнее...
      </router-link>
    </div>
  </div>
</template>

<script>
import getBgStyles from "@/utils/getBgStyles";

export default {
  name: "NewsListItem",
  props: {
    news: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
    },
  },
  computed: {
    bgStyles() {
      if (!this.news.photo) return null;
      return getBgStyles(this.news.photo, "center center", "cover");
    },
  },
};
</script>
