import settings from "@/settings";

export default function getBgStyles(src, bgPosition, bgSize) {
  return {
    "background-image": "url(" + settings.APP_BASE + src + ")",
    "background-repeat": "no-repeat",
    "background-position": bgPosition ? bgPosition : "center center",
    "background-size": bgSize ? bgSize : "contain",
  };
}
