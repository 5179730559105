<template>
  <div class="news">
    <div v-if="isLoading" class="loading"></div>
    <div v-else-if="!news.length" class="empty-block">Нет информации</div>
    <template v-else>
      <NewsMain :mainNews="mainNews" />

      <div class="news-list">
        <div class="container">
          <NewsListItem
            v-for="(news, i) in newsList"
            :key="news.id"
            :news="news"
            :i="i"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import NewsMain from "@/components/partials/NewsMain.vue";
import NewsListItem from "@/components/partials/NewsListItem.vue";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "News",
  components: { NewsMain, NewsListItem },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("news", ["news"]),
    ...mapGetters("news", ["mainNews", "otherNews"]),
    newsList() {
      return this.otherNews(this.mainNews.id);
    },
  },
  mounted() {
    this.getNews();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("news", ["getNews", "resetNews"]),
  },
  beforeDestroy() {
    this.resetNews();
  },
};
</script>
