<template>
  <div
    class="news-main"
    :style="bgStyles"
    @click="$router.push({ name: 'NewsDetail', params: { id: mainNews.id } })"
  >
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <h1 class="page-title">{{ mainNews.headline }}</h1>
    </Page>
    <div>
      <div class="container">
        <div class="news-main__text" v-html="mainNews.simple_text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";
import getBgStyles from "@/utils/getBgStyles";

export default {
  name: "NewsMain",
  props: {
    mainNews: Object,
  },
  components: { Page },
  computed: {
    bgStyles() {
      if (!this.mainNews.photo) return null;
      return getBgStyles(this.mainNews.photo, "center center", "cover");
    },
  },
};
</script>
